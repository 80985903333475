import {
  setToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
} from '../utils'

export default {
  login: ({ username, password }) => {
    const request = new Request(`/api/v1/admin/oauth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.message)
        }
        return response.json()
      })
      .then(({ token, role, id, accessRoutes, accessAllRoutes }) => {
        setToLocalStorage(
          'permissions',
          JSON.stringify({
            role,
            accessRoutes,
            accessAllRoutes,
          })
        )
        setToLocalStorage('token', token)
        setToLocalStorage('id', id)

        return Promise.resolve()
      })
  },
  checkAuth: () => {
    return getFromLocalStorage('token') ? Promise.resolve() : Promise.reject()
  },
  logout: () => {
    const token = getFromLocalStorage('token')

    removeFromLocalStorage('token')
    removeFromLocalStorage('permissions')
    removeFromLocalStorage('city')
    removeFromLocalStorage('id')

    if (token) {
      const request = new Request(`/api/v1/admin/oauth/logout`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization-Token': token,
        }),
      })
      fetch(request)
    }

    return Promise.resolve()
  },
  getPermissions: () => {
    const permissions = getFromLocalStorage('permissions')

    return permissions
      ? Promise.resolve(JSON.parse(permissions))
      : Promise.reject()
  },
  checkError: (error) => {
    const { status } = error

    if (status === 401 || status === 403) {
      removeFromLocalStorage('token')
      removeFromLocalStorage('permissions')
      removeFromLocalStorage('city')
      removeFromLocalStorage('id')
      return Promise.reject({ redirectTo: '/login' })
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
}
