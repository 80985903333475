import React, { useMemo } from 'react'

import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  BooleanField,
  DateField,
  ReferenceField,
  useListContext,
  Pagination,
  DateInput,
  EditButton,
} from 'react-admin'
import { ALL_STATUSES } from '../../i18n/statuses'
import ExportButton from '../../components/ExportButton'

const OrdersPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 200]} {...props} />
)

const OrdersListFilters = (props) => {
  const { currentSort, page, perPage, resource } = useListContext()
  const { filterValues } = props
  const { place } = filterValues

  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
      <ReferenceInput
        source="courier"
        reference="poster_users"
        alwaysOn
        filter={{ checkPlace: false, place, removed: false }}
      >
        <SelectInput source="name" />
      </ReferenceInput>
      <DateInput source="dateStart" alwaysOn label="Дата начала" />
      <DateInput source="dateEnd" alwaysOn label="Дата окончания" />
      <ExportButton
        source="export"
        alwaysOn
        orders
        page={page}
        perPage={perPage}
        order={currentSort.order}
        sort={currentSort.field}
        resource={resource}
        {...filterValues}
      />
    </Filter>
  )
}

const DeliveryField = (props) => {
  const {
    record: { deliveryInfo },
    record,
  } = props

  const { address1 = '', address2 = '' } = deliveryInfo

  return (
    <TextField
      {...props}
      record={{ ...record, deliveryInfo: [address1, address2].join(', ') }}
    />
  )
}

const ClientField = (props) => {
  const {
    record: { clientInfo },
    record,
  } = props

  const { firstName = '', lastName = '', phone } = clientInfo || {}

  return (
    <TextField
      {...props}
      record={{
        ...record,
        clientInfo: [firstName, lastName, phone].filter(Boolean).join(', '),
      }}
    />
  )
}

const StatusField = (props) => {
  const {
    record: { currentStatus },
    record,
  } = props

  return (
    <TextField
      {...props}
      record={{
        ...record,
        currentStatus: ALL_STATUSES[currentStatus],
      }}
    />
  )
}

const CommentField = (props) => {
  const {
    record: { comment = '' },
    record,
  } = props

  const regexp = /Время доставки:\s(\d{2}:\d{2}\s-\s\d{2}:\d{2}|\S+\s\S+)./gi

  const phrase = comment.match(regexp)

  return (
    <TextField
      {...props}
      record={{
        ...record,
        comment: phrase ? phrase[0] : '',
      }}
    />
  )
}

const OrdersList = (props) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      filters={<OrdersListFilters />}
      empty={false}
      sort={{ order: 'DESC', field: 'orderName' }}
      pagination={<OrdersPagination />}
      perPage={25}
      filterDefaultValues={filterDefaultValues}
    >
      <Datagrid>
        <TextField source="orderName" />
        <StatusField source="currentStatus" />
        <DeliveryField source="deliveryInfo" />
        <ClientField source="clientInfo" />
        <CommentField source="comment" />
        <DateField source="posterOrderCreatedDate" showTime />
        <DateField source="createdAtDate" showTime />
        <DateField source="courierStartAt" showTime />
        <DateField source="courierFinishedAt" showTime />
        <TextField source="deliveryTime" />
        <TextField source="totalTime" />
        <BooleanField source="needToRedirect" />
        <TextField source="redirectDeliveryAddress" />
        <TextField source="temperature" />
        <BooleanField source="giveCertificate" />
        <TextField source="certificate" />
        <ReferenceField source="courier" reference="poster_users" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default OrdersList
