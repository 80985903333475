import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import FileDownload from 'js-file-download'
import { getFromLocalStorage } from '../../utils'

const sanitizeParams = (params) => {
  const {
    allowEmpty,
    alwaysOn,
    variant,
    record,
    resource,
    defaultValue,
    margin,
    helperText,
    ...props
  } = params

  return props
}

const ExportButton = ({
  resource = 'orders',
  filename = 'orders',
  page = 1,
  perPage = 10,
  ...props
}) => {
  const downloadXls = () => {
    const params = sanitizeParams(props)

    let stringifyParams = ''
    const paramKeys = Object.keys(params)

    paramKeys.forEach((paramKey, index) => {
      if (params[paramKey]) {
        stringifyParams += `${paramKey}=${
          ['dateStart', 'dateEnd'].includes(paramKey)
            ? +new Date(params[paramKey])
            : params[paramKey]
        }${paramKeys[index + 1] ? '&' : ''}`
      }
    })
    const end = perPage * page

    const start = end - perPage

    axios({
      url: `/api/v1/admin/${resource}?formatType=xlsx&start=${start}&end=${end}&${stringifyParams}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Authorization-Token': getFromLocalStorage('token'),
      },
    }).then((response) => {
      FileDownload(
        response.data,
        `${filename}_${new Date().toLocaleString()}.xlsx`
      )
    })
  }

  return (
    <div
      style={{
        marginBottom: 12,
      }}
    >
      <Button color="primary" onClick={downloadXls}>
        <GetAppIcon color="primary" />
        &nbsp;&nbsp;Экспорт
      </Button>
    </div>
  )
}

export default ExportButton
