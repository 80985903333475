import React, { useState } from 'react'
import lodashGet from 'lodash/get'
import classnames from 'classnames'

import { useMediaQuery } from '@material-ui/core'
import LabelIcon from '@material-ui/icons/Label'
import { makeStyles } from '@material-ui/core/styles'
import {
  MenuItemLink,
  getResources,
  useTranslate,
  DashboardMenuItem,
} from 'react-admin'
import { useSelector } from 'react-redux'
import DefaultIcon from '@material-ui/icons/ViewList'
import CustomMenuItem from './CustomMenuItem'

export const MENU_WIDTH = 240
export const CLOSED_MENU_WIDTH = 55

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      marginBottom: '1em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
  }),
  { name: 'RaMenu' }
)

const TreeMenu = (props) => {
  const {
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...rest
  } = props

  /**
   * Initialising the initialExpansionState and
   * active parent resource name at the time of
   * initial menu rendering.
   */
  const initialExpansionState = {}
  let parentActiveResName = null

  const [state, setState] = useState(initialExpansionState)
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const translate = useTranslate()
  const open = useSelector(
    (prevOpenState) => prevOpenState.admin.ui.sidebarOpen
  )
  const pathname = useSelector(
    (prevOpenPathname) => prevOpenPathname.router.location.pathname
  )
  const resources = useSelector(getResources)
  const visibleResources = resources.filter((res) => !res.options.hidden)
  const hasList = (resource) => resource.hasList

  const handleToggle = (parent) => {
    /**
     * Handles toggling of parents dropdowns
     * for resource visibility
     */
    setState((prevState) => {
      return { [parent]: !prevState[parent] }
    })
  }

  const isParent = (resource) =>
    /**
     * Check if the given resource is a parent
     * i.e. dummy resource for menu parenting
     */
    resource.options &&
    Object.prototype.hasOwnProperty.call(resource.options, 'isMenuParent') &&
    resource.options.isMenuParent

  const isOrphan = (resource) =>
    /**
     * Check if the given resource is an orphan
     * i.e. has no parents defined. Needed as
     * these resources are supposed to be rendered
     * as is
     *
     */
    resource.options &&
    !Object.prototype.hasOwnProperty.call(resource.options, 'menuParent') &&
    !Object.prototype.hasOwnProperty.call(resource.options, 'isMenuParent')

  const isChildOfParent = (resource, parentResource) =>
    /**
     * Returns true if the given resource is the
     * mapped child of the parentResource
     */
    resource.options &&
    Object.prototype.hasOwnProperty.call(resource.options, 'menuParent') &&
    resource.options.menuParent === parentResource.name

  const MenuItem = (resource) => (
    /**
     * Created and returns the MenuItemLink object component
     * for a given resource.
     */
    <MenuItemLink
      key={resource.name}
      to={`/${resource.name}`}
      primaryText={
        (resource.options && resource.options.label) ||
        translate(`resources.${resource.name}.menu`)
      }
      leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
      dense={dense}
      sidebarIsOpen={open}
    />
  )

  /**
   * Mapping a "parent" entry and then all its children to the "tree" layout
   */
  const mapParentStack = (parentResource) => {
    return (
      <CustomMenuItem
        key={parentResource.name}
        handleToggle={() => handleToggle(parentResource.name)}
        isOpen={
          state[parentResource.name] ||
          parentActiveResName === parentResource.name
        }
        sidebarIsOpen={open}
        name={
          parentResource.options.label ||
          `resources.${parentResource.name}.menu`
        }
        icon={parentResource.icon ? <parentResource.icon /> : <LabelIcon />}
        dense={dense}
      >
        {
          // eslint-disable-next-line
          visibleResources
            .filter(
              (resource) =>
                isChildOfParent(resource, parentResource) && hasList(resource)
            )
            .map((childResource) => {
              return MenuItem(childResource)
            })
        }
      </CustomMenuItem>
    )
  }

  /**
   * Mapping independent (without a parent) entries
   */
  const mapIndependent = (independentResource) =>
    hasList(independentResource) && MenuItem(independentResource)

  /**
   * Initialise all parents to inactive first.
   * Also find the active resource name.
   */
  visibleResources.forEach((resource) => {
    if (isParent(resource)) {
      initialExpansionState[resource.name] = false
    } else if (
      pathname.startsWith(`/${resource.name}`) &&
      Object.prototype.hasOwnProperty.call(resource.options, 'menuParent')
    ) {
      parentActiveResName = resource.options.menuParent
    }
  })

  /**
   * The final array which will hold the array
   * of resources to be rendered
   */
  const resRenderGroup = []

  /**
   * Looping over all resources and pushing the menu tree
   * for rendering in the order we find them declared in
   */
  visibleResources.forEach((r) => {
    if (isParent(r)) resRenderGroup.push(mapParentStack(r))
    if (isOrphan(r)) resRenderGroup.push(mapIndependent(r))
  })

  const classes = useStyles(props)

  return (
    <div
      className={classnames(
        classes.main,
        {
          [classes.open]: open,
          [classes.closed]: !open,
        },
        className
      )}
      {...rest}
    >
      {hasDashboard && <DashboardMenuItem />}
      {resRenderGroup}
      {isXSmall && logout}
    </div>
  )
}

export default TreeMenu
