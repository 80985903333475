export const supervisorResourceRoutes = [
  'poster_users',
  'orders',
  'places',
  'statistics',
  'delivery_districts',
]

export const supervisorResourceRoutesWithChildren = {
  places: true,
  poster_users: true,
  orders: true,
  statistics: true,
  delivery_districts: true,
}

export const routesWithRoles = {
  supervisor: {
    routes: supervisorResourceRoutes,
    withChildren: supervisorResourceRoutesWithChildren,
  },
}

export const resourcesByValues = (values, role) => {
  const { routes, withChildren } = routesWithRoles[role]

  return routes.map((route) => {
    const resource = withChildren[route]

    if (resource) {
      if (typeof resource === 'boolean') {
        return { id: route, name: route, disabled: true, checked: true }
      }

      if (Array.isArray(resource)) {
        return {
          id: route,
          name: route,
          disabled: true,
          checked:
            values.filter((childrenRoute) => resource.includes(childrenRoute))
              .length > 0,
        }
      }
    }

    return { id: route, name: route, checked: values.includes(route) }
  })
}

export const checkedResourcesByValuesIds = (values, role) => {
  return resourcesByValues(values, role)
    .filter((elll) => elll.checked)
    .map((ell) => ell.id)
}

export const isPermissionExist = (permissions, path) => {
  if (
    !path ||
    !permissions ||
    (permissions &&
      !permissions.accessAllRoutes &&
      !permissions.accessRoutes.includes(path))
  ) {
    return false
  }

  return true
}
