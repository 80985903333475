import React from 'react'

import { List, Datagrid, TextField, EditButton } from 'react-admin'

const CityList = (props) => {
  return (
    <List {...props} exporter={false}>
      <Datagrid>
        <TextField source="name" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default CityList
