const baseFields = {
  id: 'Id',
  createdAt: 'Создан',
  updatedAt: 'Обновлён',
  q: 'Поиск',
}

export default {
  resources: {
    city: {
      name: 'Город |||| Города',
      fields: {
        name: 'Название',
        ...baseFields,
      },
      menu: 'Города',
      empty: 'Нет созданных городов.',
      invite: 'Вы хотите создать новый город?',
    },
    places: {
      name: 'Заведение |||| Заведения',
      fields: {
        ...baseFields,
        name: 'Название',
        token: 'Ключ доступа',
        addressLogin: "Адрес (логин) Poster'a",
        city: 'Город',
        serverAddress: 'Адрес сервера',
        additionalServerAddress: 'Дополнительный адрес',
        terminalLogin: 'Логин терминала',
        terminalPasssword: 'Пароль терминала',
        webAdminLogin: 'Web логин',
        webAdminPasssword: 'Web пароль',
        isEnabled: 'Работает?',
      },
      menu: 'Заведения',
      empty: 'Нет созданных заведений.',
      invite: 'Вы хотите создать новое заведение?',
    },

    // Разделы меню
    menu_settings: {
      name: 'Настройки',
      menu: 'Настройки',
    },

    admin: {
      name: 'Пользователи |||| Пользователей',
      menu: 'Пользователи',
      fields: {
        ...baseFields,
        place: 'Заведение',
        places: 'Заведения',
        city: 'Город',
        name: 'Имя',
        email: 'e-mail',
        password: 'Пароль',
        passwordConfirmation: 'Повторите пароль',
        role: 'Роль',
        accessAllRoutes: 'Доступ к всем ролям',
      },
      empty: 'Нет созданных пользователей.',
      invite: 'Вы хотите создать нового пользователя?',
    },

    poster_users: {
      name: 'Poster пользователь |||| Poster пользователи',
      fields: {
        ...baseFields,
        name: 'ФИО',
        posterName: 'ФИО Постер',
        userId: 'Poster Id',
        posPass: 'Poster PIN',
        phone: 'Телефон',
        roleId: 'Id роли',
        roleName: 'Роль',
        isShiftOpened: 'На смене?',
        place: 'Заведение',
        removed: 'Удален?',
      },
      menu: 'Poster пользователи',
      empty: 'Нет созданных Poster пользователей.',
    },
    orders: {
      name: 'Заказ |||| Заказы',
      fields: {
        ...baseFields,
        place: 'Заведение',
        orderName: 'Номер заказа',
        currentStatus: 'Статус заказа',
        deliveryInfo: 'Адрес',
        clientInfo: 'Клиент',
        comment: 'Комментарий',
        courierStartAt: 'Курьер принял заказ',
        courierFinishedAt: 'Курьер выдал заказ',
        needToRedirect: 'Переадресация',
        redirectDeliveryAddress: 'Адрес переадресации',
        temperature: 'Температура заказа',
        certificate: 'Сертификат',
        courier: 'Курьер',
        createdAtDate: 'Время попадания в MokoIS',
        deliveryTime: 'Время доставки',
        totalTime: 'Время от попадания в систему Poster до выдачи клиенту',
        posterOrderCreatedDate: 'Время попадания в систему Poster',
        dateStart: 'Дата начала',
        dateEnd: 'Дата окончания',
        giveCertificate: 'Выдали сертификат',
      },
      menu: 'Заказы',
      empty: 'Нет созданных заказов.',
    },
    statistics: {
      name: 'Статистика',
      fields: {
        ...baseFields,
        totalOrdersCount: 'Общее кол-во заказов',
        atSelectedTimeOrdersCount: 'Заказы на указанное время',
        closestTimeOrdersCount: 'Основные заказы (ближ. время)',
        failedDeliveryOrdersCount: 'Неуспешные доставки',
        canceledByManagerOrdersCount: 'Заказы отменённые менеджером',
        averageDeliveryTime: 'Среднее время доставки',
        totalTimeForMainOrders:
          'Среднее время от заказа до выдачи (ближ. заказы)',
        place: 'Заведение',
        courier: 'Курьер',
        dateStart: 'Дата начала',
        dateEnd: 'Дата окончания',
        expiredDeliveryCount: 'Кол-во просроченных доставок (>= 1 часа)',
        giveCertificateCount: 'Кол-во выданных сертификатов',
        couriersTotalSalaryByPeriod:
          'Деньги заработанные курьером(-ами) за доставки',
      },
      menu: 'Статистика',
      empty: 'Нет статистики по заданным данным.',
    },

    delivery_districts: {
      name: 'Доставка |||| Доставки',
      fields: {
        ...baseFields,
        name: 'Название',
        place: 'Заведение',
        deliveryCost: 'Стоимость доставки',
        names: 'Названия на других языках',
        deliveryType: 'Тип доставки',
      },
      menu: 'Доставки',
      empty: 'Нет созданных доставок по заданным данным.',
      invite: 'Вы хотите создать новую доставку?',
    },
  },
}
