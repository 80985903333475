const TOKEN_NAME = 'userToken'

export const saveTokenToLocalStorage = (token) => {
  localStorage.setItem(TOKEN_NAME, token)
}

export const getTokenFromLocalStorage = () => {
  return localStorage.getItem(TOKEN_NAME)
}

export const removeTokenFromLocalStorage = () => {
  localStorage.removeItem(TOKEN_NAME)
}
