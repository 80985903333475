import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CMS from './cms'
import { App } from './app'
import { routes } from './app/routes/routes'

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path={routes.admin} exact component={CMS} />
      <Route path={routes.base} component={App} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
)
