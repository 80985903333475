import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import { AppBar } from '../AppBar'
import { routesWithNames } from '../../routes/routes'

export const BasePage = ({ children, additionalInfoMessage }) => {
  const location = useLocation()

  return (
    <div>
      <AppBar
        title={routesWithNames[location.pathname]}
        currentPathName={location.pathname}
        additionalInfoMessage={additionalInfoMessage}
      />
      <div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ padding: additionalInfoMessage ? '3em 0 ' : '1em 0' }}
          overflow="scroll"
        >
          <Container>
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ marginLeft: '-4px', marginTop: '2em' }}
            >
              {children}
            </Grid>
          </Container>
        </Box>
      </div>
    </div>
  )
}
