import React from 'react'

import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  PasswordInput,
  BooleanInput,
} from 'react-admin'

const PlaceCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput source="token" validate={required()} />
      <TextInput source="addressLogin" validate={required()} />
      <TextInput source="serverAddress" />
      <TextInput source="additionalServerAddress" />
      <TextInput source="terminalLogin" validate={required()} />
      <PasswordInput source="terminalPassword" validate={required()} />
      <TextInput source="webAdminLogin" validate={required()} />
      <PasswordInput source="webAdminPassword" validate={required()} />
      <BooleanInput source="isEnabled" />
      <ReferenceInput source="city" reference="city" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default PlaceCreate
