import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { routesWithNames } from '../../routes/routes'

export const DrawerContent = memo(({ logoutCallback, currentPathName }) => {
  const keys = Object.keys(routesWithNames)
  const history = useHistory()

  const changeUrl = (url) => {
    history.push(url)
  }

  return (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {keys.map((text) => (
          <ListItem
            button
            key={text}
            sx={{
              fontWeight: text === currentPathName ? 'bold' : 'normal',
            }}
            onClick={() => changeUrl(text)}
          >
            <ListItemText primary={routesWithNames[text]} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={logoutCallback}>
          <ListItemText primary="Выйти" />
        </ListItem>
      </List>
    </Box>
  )
})
