import React from 'react'

import {
  Edit,
  SimpleForm,
  DateTimeInput,
  BooleanInput,
  TextInput,
  FormDataConsumer,
  SelectInput,
} from 'react-admin'
import { ALL_STATUSES } from '../../i18n/statuses'

const OrdersEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect={false}>
      <DateTimeInput source="courierFinishedAt" />
      <TextInput source="comment" />
      <BooleanInput source="giveCertificate" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const { giveCertificate } = formData

          return (
            <>
              {giveCertificate && <TextInput source="certificate" {...rest} />}
            </>
          )
        }}
      </FormDataConsumer>
      <SelectInput
        source="currentStatus"
        choices={Object.keys(ALL_STATUSES).map((key) => ({
          id: key,
          name: ALL_STATUSES[key],
        }))}
      />
    </SimpleForm>
  </Edit>
)

export default OrdersEdit
