import React, { useEffect, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import { BasePage, CardItem } from '../../components'
import { useFetch } from '../../hooks/useFetch'
import { ordersListByDay } from '../../api'

export const OrdersList = () => {
  const [{ data }, fetchOrders] = useFetch(ordersListByDay)

  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrders()
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  const mappedItems = useMemo(() => {
    if (!data || !data.length) {
      return <Grid sx={{ marginTop: '4em' }}>У Вас пока что нет заказов</Grid>
    }

    return data.map((order) => {
      const { id } = order
      return <CardItem key={id} {...order} onlyAddress />
    })
  }, [data])

  const moneyPerDay = useMemo(() => {
    if (!data || !data.length) {
      return 0
    }

    return data
      .filter(
        (order) =>
          !['CanceledByManager', 'CourierChange', 'InDelivery'].includes(
            order.currentStatus
          )
      )
      .reduce((prev, current) => {
        const { deliveryPriceForCourier = 0, needToRedirect } = current

        return (
          prev +
          (needToRedirect
            ? deliveryPriceForCourier * 2
            : deliveryPriceForCourier)
        )
      }, 0)
  }, [data])

  return (
    <BasePage additionalInfoMessage={`${moneyPerDay} ₴`}>
      {mappedItems}
    </BasePage>
  )
}
