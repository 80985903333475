import React, { useState, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { AppBar as AppBarMui } from '@mui/material'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useFetch } from '../../hooks'
import { logoutUser } from '../../api/oauth'
import { routes } from '../../routes'
import { removeTokenFromLocalStorage } from '../../utils'
import { DrawerContent } from '../DrawerContent'

export const AppBar = memo(
  ({ title, currentPathName, additionalInfoMessage }) => {
    const history = useHistory()
    const [, logoutCallback] = useFetch(logoutUser, {
      fetchOnMount: false,
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const logout = async () => {
      const [error] = await logoutCallback()
      if (!error) {
        removeTokenFromLocalStorage()

        history.push(routes.login)
      }
      handleClose()
    }

    const openDrawer = () => {
      setIsOpen(true)
    }

    const handleCloseDrawer = () => {
      setIsOpen(false)
    }

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBarMui position="fixed">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={openDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Выйти</MenuItem>
              </Menu>
            </div>
          </Toolbar>
          <Drawer anchor="left" open={isOpen} onClose={handleCloseDrawer}>
            <DrawerContent
              logoutCallback={logout}
              currentPathName={currentPathName}
            />
          </Drawer>
          {additionalInfoMessage && (
            <Box sx={{ textAlign: 'center' }} mb={1}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Заработано за день: {additionalInfoMessage}
              </Typography>
            </Box>
          )}
        </AppBarMui>
      </Box>
    )
  }
)
