import React, { useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import { BasePage, CardItemByPlace } from '../../components'
import { useFetch } from '../../hooks/useFetch'
import { ordersListByPlace } from '../../api'

export const OrdersByPlaceList = () => {
  const [{ data }, fetchOrders] = useFetch(ordersListByPlace)
  const [isScrolledDown, setIsScrolledDown] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrders()
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (data && !isScrolledDown) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
        setIsScrolledDown(true)
      }, 300)
    }
  }, [data, isScrolledDown])

  const mappedItems = useMemo(() => {
    if (!data || !data.length) {
      return <Grid sx={{ marginTop: '4em' }}>У Вас пока что нет заказов</Grid>
    }

    return data.map((order) => {
      const { id } = order

      return <CardItemByPlace key={id} {...order} />
    })
  }, [data])

  return <BasePage>{mappedItems}</BasePage>
}
