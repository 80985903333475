import React, { memo } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import { ALL_STATUSES } from '../../i18n/statuses'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

export const CardItem = memo((cardProps) => {
  const {
    id,
    orderName,
    clientInfo,
    deliveryInfo,
    onlyAddress = false,
    handleOpen,
    currentStatus,
    comment,
    openMapDialog,
    deliveryPriceForCourier,
    ...otherProps
  } = cardProps

  const handleCardClick = () => {
    handleOpen({
      id,
      orderName,
      ...otherProps,
    })
  }

  const handleMapClick = () => {
    openMapDialog({
      id,
      orderName,
      deliveryInfo,
    })
  }

  const { firstName, lastName, phone } = clientInfo || {}

  const address = [deliveryInfo.address1, deliveryInfo.address2]
    .filter(Boolean)
    .join(', ')

  return (
    <Grid key={id} sx={{ paddingTop: '2em', marginLeft: '-4px' }}>
      <Item>
        <h3
          style={{
            backgroundColor:
              typeof deliveryPriceForCourier === 'number' &&
              deliveryPriceForCourier === 0
                ? 'red'
                : 'unset',
          }}
        >
          Заказ №: {orderName}
        </h3>
        <Grid sx={{ textAlign: 'left' }}>
          {!onlyAddress && clientInfo && (
            <>
              <p>
                Клиент: {firstName} {lastName}{' '}
              </p>
              <p>
                Телефон: <Link href={`tel:${phone}`}>{phone}</Link>
              </p>
              {comment && <p>Комментарий: {comment}</p>}
              <p>
                Стоимость: <b>{deliveryInfo.billAmount} грн.</b>
              </p>
            </>
          )}

          {onlyAddress && (deliveryInfo.address1 || deliveryInfo.address2) && (
            <p>
              Адрес: <br />
              {address}
            </p>
          )}

          {!onlyAddress && clientInfo && (
            <p>
              Адрес: <br />
              <Button type="link" onClick={handleMapClick}>
                {address}
              </Button>
            </p>
          )}
        </Grid>
        {onlyAddress && (
          <Grid sx={{ textAlign: 'left' }}>
            <p>
              <b>
                Статус: &nbsp;
                {ALL_STATUSES[currentStatus]}
              </b>
            </p>
          </Grid>
        )}
        {!onlyAddress && (
          <Button variant="contained" onClick={handleCardClick}>
            Выполнено
          </Button>
        )}
      </Item>
    </Grid>
  )
})
