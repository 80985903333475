import React from 'react'
import { Resource } from 'react-admin'
import { Redirect } from 'react-router-dom'

import SettingsIcon from '@material-ui/icons/Settings'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import PlaceIcon from '@material-ui/icons/Place'
import PeopleIcon from '@material-ui/icons/People'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import ApartmentIcon from '@mui/icons-material/Apartment'

import cityProps from './city'
import placesProps from './place'
import adminProps from './admin'
import posterUsersProps from './posterUsers'
import ordersProps from './orders'
import statisticsProps from './statistics'
import deliveryDistrictsProps from './deliveryDistricts'

const adminRoleRoutes = [
  <Resource
    name="menu_settings"
    key="menu_settings"
    options={{ isMenuParent: true }}
    icon={SettingsIcon}
    permissions="admin"
  />,
  <Resource
    name="city"
    key="city"
    {...cityProps}
    options={{ menuParent: 'menu_settings' }}
    icon={LocationCityIcon}
    permissions="admin"
  />,
  <Resource
    name="places"
    key="places"
    {...placesProps}
    options={{ menuParent: 'menu_settings' }}
    icon={PlaceIcon}
    permissions="admin"
  />,
  <Resource
    name="delivery_districts"
    key="delivery_districts"
    {...deliveryDistrictsProps}
    options={{ menuParent: 'menu_settings' }}
    icon={ApartmentIcon}
    permissions="admin"
  />,
  <Resource
    name="admin"
    key="admin"
    {...adminProps}
    options={{ menuParent: 'menu_settings' }}
    icon={PeopleIcon}
    permissions="admin"
  />,
  <Resource
    name="poster_users"
    key="poster_users"
    {...posterUsersProps}
    icon={PeopleIcon}
    permissions="admin"
  />,
  <Resource
    name="orders"
    key="orders"
    {...ordersProps}
    icon={ShoppingCartIcon}
    permissions="admin"
  />,
  <Resource
    name="statistics"
    key="statistics"
    {...statisticsProps}
    icon={EqualizerIcon}
    permissions="admin"
  />,
]

const allSupervisorRoutes = [
  <Resource
    name="places"
    key="places"
    icon={PlaceIcon}
    options={{ hidden: true }}
  />,
  <Resource
    name="delivery_districts"
    key="delivery_districts"
    {...deliveryDistrictsProps}
    icon={ApartmentIcon}
  />,
  <Resource
    name="poster_users"
    key="poster_users"
    {...posterUsersProps}
    icon={PeopleIcon}
  />,
  <Resource
    name="orders"
    key="orders"
    {...ordersProps}
    icon={ShoppingCartIcon}
  />,
  <Resource
    name="statistics"
    key="statistics"
    {...statisticsProps}
    icon={EqualizerIcon}
  />,
]

export const adminRoutes = (permissions) => {
  if (!permissions) {
    return [<Redirect to="/" />]
  }

  const { role, accessAllRoutes, accessRoutes } = permissions

  if (role === 'admin') {
    return adminRoleRoutes
  }

  if (accessAllRoutes) {
    return allSupervisorRoutes
  }

  return allSupervisorRoutes.filter((resource) =>
    accessRoutes.includes(resource.props.name)
  )
}
