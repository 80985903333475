import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import {
  BasePage,
  CardItem,
  CardDialog,
  MapsCardDialog,
} from '../../components'
import { useFetch } from '../../hooks/useFetch'
import { activeOrdersList, updateOrderById } from '../../api/orders'

export const ActiveOrdersList = () => {
  const [{ data }, fetchOrders] = useFetch(activeOrdersList)
  const [, fetchUpdate] = useFetch(updateOrderById, { fetchOnMount: false })

  const [isOpen, setIsOpen] = useState(false)
  const [isMapsOpen, setIsMapsOpen] = useState(false)
  const [activeCard, setActiveCard] = useState(null)
  const [completedIds, setCompletedIds] = useState([])

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setActiveCard(null)
  }, [])

  const handleOpen = useCallback((cardInfo) => {
    setIsOpen(true)
    setActiveCard(cardInfo)
  }, [])

  const openMapDialog = useCallback((cardInfo) => {
    setActiveCard(cardInfo)
    setIsMapsOpen(true)
  }, [])

  const handleSubmit = async (values) => {
    const { id } = activeCard

    const [error] = await fetchUpdate(id, values)

    if (!error) {
      setCompletedIds([...completedIds, id])
    } else {
      window.alert(
        'Произошла ошибка при обновлении статуса заказа. Попробуйте снова или обратитесь к менеджеру.'
      )
    }

    handleClose()
  }

  const closeMap = (state) => {
    setIsMapsOpen(state)
    setActiveCard(null)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrders()
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  const mappedItems = useMemo(() => {
    const noItemsLength = (
      <Grid sx={{ marginTop: '4em' }}>У Вас пока что нет заказов</Grid>
    )

    if (!data || !data.length) {
      return noItemsLength
    }

    const filteredItems = data.filter((card) => !completedIds.includes(card.id))

    if (!filteredItems.length) {
      return noItemsLength
    }

    return filteredItems.map((order) => {
      const { id } = order

      return (
        <CardItem
          key={id}
          {...order}
          handleOpen={handleOpen}
          openMapDialog={openMapDialog}
        />
      )
    })
  }, [data, completedIds])

  return (
    <BasePage>
      <CardDialog
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={handleSubmit}
        activeCard={activeCard}
      />
      <MapsCardDialog
        isOpen={isMapsOpen}
        setIsOpen={closeMap}
        activeCard={activeCard}
      />
      {mappedItems}
    </BasePage>
  )
}
