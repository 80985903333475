import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'

import { useFetch } from '../../hooks/useFetch'
import { visicomOrderAddressById } from '../../api/orders'

export const MapsCardDialog = (props) => {
  const [{ data }, fetch] = useFetch(visicomOrderAddressById, {
    fetchOnMount: false,
  })

  const { isOpen, setIsOpen, activeCard } = props

  const { orderName = '', deliveryInfo } = activeCard || {}

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const onGoogleMapClick = () => {
    setIsOpen(false)

    const url = `https://maps.google.com/maps?q=${encodeURIComponent(
      [deliveryInfo.address1, deliveryInfo.address2].filter(Boolean).join(', ')
    )}`

    openInNewTab(url)
  }

  const onVisicomMapClick = () => {
    setIsOpen(false)

    openInNewTab(data.link)
  }

  const onOpenStreetMapClick = () => {
    setIsOpen(false)

    const url = `https://www.openstreetmap.org/search?query=${encodeURIComponent(
      [deliveryInfo.address1, deliveryInfo.address2].filter(Boolean).join(', ')
    )}`

    openInNewTab(url)
  }

  useEffect(() => {
    if (isOpen) {
      fetch(activeCard.id)
    }
  }, [isOpen])

  return (
    <Dialog open={isOpen} keepMounted>
      <DialogTitle>Показать на карте - заказ № {orderName}</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Button onClick={onGoogleMapClick}>Google Maps</Button>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Button onClick={onOpenStreetMapClick}>OpenStreet Maps</Button>
        </FormControl>
        {data && data.link && (
          <FormControl fullWidth>
            <Button onClick={onVisicomMapClick}>Visicom</Button>
          </FormControl>
        )}
      </DialogContent>
    </Dialog>
  )
}
