import React, { memo } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { ALL_STATUSES } from '../../i18n/statuses'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const renderStatusOrDate = (status, date) => {
  const isDelivered = ['Delivered', 'RepeatDelivery'].includes(status)

  if (!isDelivered) {
    return ALL_STATUSES[status]
  }

  const parsedDate = new Date(date)

  return `${parsedDate.getHours()}:${parsedDate.getMinutes()}`
}

export const CardItemByPlace = memo((cardProps) => {
  const {
    id,
    orderName,
    currentStatus,
    courierFinishedAt,
    courier: { name },
  } = cardProps

  return (
    <Grid key={id} sx={{ paddingTop: '2em', marginLeft: '-4px' }}>
      <Item>
        <h3>{name}</h3>

        <Grid sx={{ textAlign: 'center' }}>
          <Typography variant="body1" gutterBottom>
            {orderName}
          </Typography>
        </Grid>

        <Typography variant="body1" gutterBottom>
          {renderStatusOrDate(currentStatus, courierFinishedAt)}
        </Typography>
      </Item>
    </Grid>
  )
})
