import React from 'react'

import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  Filter,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import { DELIVERY_TYPES } from '../../i18n/statuses'

const DistrictsListFilters = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
    </Filter>
  )
}

const DeliveryField = (props) => {
  const {
    record: { deliveryType },
    record,
  } = props

  return (
    <TextField
      source="deliveryType"
      {...props}
      record={{ ...record, deliveryType: DELIVERY_TYPES[deliveryType] }}
    />
  )
}

const DistrictList = (props) => (
  <List {...props} exporter={false} filters={<DistrictsListFilters />}>
    <Datagrid>
      <TextField source="name" />
      <ReferenceField source="place" reference="places">
        <TextField source="name" />
      </ReferenceField>
      <DeliveryField source="deliveryType" />
      <EditButton />
    </Datagrid>
  </List>
)

export default DistrictList
