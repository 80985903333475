import axios from 'axios'
import { removeTokenFromLocalStorage, getTokenFromLocalStorage } from '../utils'
import { routes } from '../routes/routes'

const LOGIN_API_PATH = '/v1/courier/oauth/login'
const LOGOUT_API_PATH = '/v1/courier/oauth/logout'

const UNAUTHORIZED_STATUS = 401

const checkForSessionLogout = (error) => {
  if (error.response.status === UNAUTHORIZED_STATUS) {
    removeTokenFromLocalStorage()
    window.location.replace(routes.login)
  }
}

const xhrBase = () => {
  const xhr = axios.create()

  xhr.interceptors.request.use(
    (config) => {
      const token = getTokenFromLocalStorage()

      if (token) {
        config.headers.common['authorization-token'] = token
      }

      return config
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  xhr.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      // проверяем на 401 статус, чтобы редирекнуть на /logout,
      // исключаем проверку при логине и логауте
      if (
        !error.config.url.includes(LOGIN_API_PATH) &&
        !error.config.url.includes(LOGOUT_API_PATH)
      ) {
        checkForSessionLogout(error)
      }

      return Promise.reject(error.response.data)
    }
  )

  return xhr
}

export const xhr = xhrBase()
