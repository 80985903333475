import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Login, OrdersList, ActiveOrdersList, OrdersByPlaceList } from './pages'
import { routes } from './routes'
import { getTokenFromLocalStorage } from './utils'

export const App = () => {
  const token = getTokenFromLocalStorage()

  return (
    <Switch>
      <Route path={routes.login} exact component={Login} />
      {token ? (
        <Switch>
          <Route path={routes.basePath} exact component={ActiveOrdersList} />
          <Route path={routes.orders} exact component={OrdersList} />
          <Route
            path={routes.orderByPlace}
            exact
            component={OrdersByPlaceList}
          />
          <Redirect from="*" to={routes.basePath} />
        </Switch>
      ) : (
        <Redirect from={routes.basePath} to={routes.login} />
      )}
    </Switch>
  )
}
