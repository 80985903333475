import React, { useMemo } from 'react'

import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
} from 'react-admin'
import { secondsToTime } from '../../utils/dateTimeHelper'

const StatisticsListFilters = (props) => {
  const { filterValues } = props
  const { place } = filterValues

  return (
    <Filter {...props}>
      <ReferenceInput source="place" reference="places" alwaysOn>
        <SelectInput source="name" />
      </ReferenceInput>
      <ReferenceInput
        source="courier"
        reference="poster_users"
        alwaysOn
        filter={{ checkPlace: false, place }}
      >
        <SelectInput source="name" />
      </ReferenceInput>
      <DateInput source="dateStart" alwaysOn label="Дата начала" />
      <DateInput source="dateEnd" alwaysOn label="Дата окончания" />
    </Filter>
  )
}

const TotalTimeField = (props) => {
  const {
    record: { totalTimeForMainOrders = 0 },
    record,
  } = props

  return (
    <TextField
      {...props}
      record={{
        ...record,
        totalTimeForMainOrders: secondsToTime(totalTimeForMainOrders),
      }}
    />
  )
}

const AverageTimeField = (props) => {
  const {
    record: { averageDeliveryTime = 0 },
    record,
  } = props

  return (
    <TextField
      {...props}
      record={{
        ...record,
        averageDeliveryTime: secondsToTime(averageDeliveryTime),
      }}
    />
  )
}

const StatisticsList = (props) => {
  const filterDefaultValues = useMemo(() => {
    return {
      dateStart: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      dateEnd: new Date(),
    }
  }, [])

  return (
    <List
      {...props}
      exporter={false}
      filters={<StatisticsListFilters />}
      empty={false}
      filterDefaultValues={filterDefaultValues}
      bulkActionButtons={false}
      sort={false}
    >
      <Datagrid>
        <TextField source="totalOrdersCount" sortable={false} />
        <TextField source="atSelectedTimeOrdersCount" sortable={false} />
        <TextField source="closestTimeOrdersCount" sortable={false} />
        <TextField source="failedDeliveryOrdersCount" sortable={false} />
        <TextField source="canceledByManagerOrdersCount" sortable={false} />
        <AverageTimeField source="averageDeliveryTime" sortable={false} />
        <TotalTimeField source="totalTimeForMainOrders" sortable={false} />
        <TextField source="expiredDeliveryCount" sortable={false} />
        <TextField source="giveCertificateCount" sortable={false} />
        <TextField source="couriersTotalSalaryByPeriod" sortable={false} />
      </Datagrid>
    </List>
  )
}

export default StatisticsList
