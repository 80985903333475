import React from 'react'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { CARD_STATUSES } from '../../i18n/statuses'

const DEFAULT_FORM_VALUES = { needToRedirect: false, setCurrentTime: true }

export const CardDialog = (props) => {
  const { onClose, isOpen, onSubmit, activeCard } = props

  const { orderName = '', courierStartAt } = activeCard || {}

  const handleClose = () => {
    onClose()
  }

  const submitForm = (values, { reset }) => {
    const {
      currentStatus,
      hours: hoursFromValues,
      minutes: minutesFromValues,
      setCurrentTime = false,
      ...formValues
    } = values

    let params = { ...formValues, currentStatus }

    if (
      !['FailedDelivery', 'CourierChange', 'CancellationOfOrder'].includes(
        currentStatus
      )
    ) {
      let hours = 0
      let minutes = 0
      let seconds = 0

      let courierFinishedAt = null

      if (setCurrentTime) {
        const today = new Date()
        hours = today.getHours()
        minutes = today.getMinutes()
        seconds = today.getSeconds()
      } else {
        const startSeconds = new Date(courierStartAt).getSeconds()
        courierFinishedAt = new Date()

        courierFinishedAt.setHours(hoursFromValues)
        courierFinishedAt.setMinutes(minutesFromValues)

        if (+new Date(courierStartAt) >= +courierFinishedAt) {
          return {
            [FORM_ERROR]:
              'Время выдачи заказа должно быть больше чем время приёма.',
          }
        }

        hours = hoursFromValues
        minutes = minutesFromValues
        seconds =
          courierFinishedAt.getSeconds() < startSeconds
            ? startSeconds
            : courierFinishedAt.getSeconds()
      }

      params = {
        ...params,
        courierFinishedAt: [
          parseInt(hours, 10),
          parseInt(minutes, 10),
          seconds,
        ].join(':'),
      }
    }

    onSubmit(params)
    reset(DEFAULT_FORM_VALUES)
  }

  return (
    <Dialog open={isOpen}>
      <Form
        onSubmit={submitForm}
        initialValues={DEFAULT_FORM_VALUES}
        render={({ handleSubmit, form: { reset }, values, submitError }) => {
          const { currentStatus, needToRedirect, setCurrentTime } = values

          const isDelivered = ['RepeatDelivery', 'Delivered'].includes(
            currentStatus
          )

          return (
            <form onSubmit={handleSubmit} style={{ width: 300 }}>
              <DialogTitle>Настройки заказа - {orderName}</DialogTitle>
              <DialogContent dividers>
                <Field
                  name="currentStatus"
                  render={({ input: { onChange, value } }) => {
                    return (
                      <FormControl fullWidth>
                        <InputLabel id="status">Статус</InputLabel>
                        <Select
                          labelId="status"
                          value={value}
                          label="Статус"
                          onChange={onChange}
                          required
                        >
                          {Object.keys(CARD_STATUSES).map((textStatus) => (
                            <MenuItem value={textStatus} key={textStatus}>
                              {CARD_STATUSES[textStatus]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )
                  }}
                />
                {isDelivered && (
                  <>
                    <Field
                      name="setCurrentTime"
                      type="checkbox"
                      render={({ input: { onChange, checked } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox onChange={onChange} checked={checked} />
                            }
                            label="Установить текущее время"
                            sx={{ mt: 2 }}
                          />
                        )
                      }}
                    />

                    {!setCurrentTime && (
                      <>
                        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
                          <Field
                            name="hours"
                            validate={(value) => {
                              if (!value) {
                                return 'Обязательно'
                              }

                              if (value) {
                                const parsedValue = parseInt(value, 10)

                                if (parsedValue > 23 || parsedValue < 0) {
                                  return 'Неправильный формат'
                                }
                              }

                              return ''
                            }}
                            render={({
                              input: { onChange, value },
                              meta: { error, dirty, touched },
                            }) => {
                              return (
                                <TextField
                                  error={dirty && Boolean(error)}
                                  variant="outlined"
                                  label="Часы"
                                  sx={{ mt: 2, mr: 2 }}
                                  onChange={onChange}
                                  value={value}
                                  type="number"
                                  min={0}
                                  max={23}
                                  helperText={touched && error}
                                  required
                                />
                              )
                            }}
                          />

                          <Field
                            name="minutes"
                            validate={(value) => {
                              if (!value) {
                                return 'Обязательно'
                              }

                              if (value) {
                                const parsedValue = parseInt(value, 10)

                                if (parsedValue > 59 || parsedValue < 0) {
                                  return 'Неправильный формат'
                                }
                              }

                              return ''
                            }}
                            render={({
                              input: { onChange, value },
                              meta: { error, dirty },
                            }) => {
                              return (
                                <TextField
                                  error={dirty && Boolean(error)}
                                  variant="outlined"
                                  label="Минуты"
                                  sx={{ mt: 2 }}
                                  onChange={onChange}
                                  value={value}
                                  type="number"
                                  min={0}
                                  max={59}
                                  helperText={dirty && error}
                                  required
                                />
                              )
                            }}
                          />
                        </FormControl>
                      </>
                    )}

                    <Field
                      name="temperature"
                      validate={(value) => {
                        if (!value) {
                          return 'Укажите температуру'
                        }
                        return ''
                      }}
                      render={({
                        input: { onChange, value },
                        meta: { error, dirty },
                      }) => {
                        return (
                          <FormControl fullWidth>
                            <TextField
                              error={dirty && Boolean(error)}
                              variant="outlined"
                              label="Температура"
                              sx={{ mt: 2 }}
                              onChange={onChange}
                              value={value}
                              type="number"
                              helperText={dirty && error}
                              required
                            />
                          </FormControl>
                        )
                      }}
                    />

                    <Field
                      name="giveCertificate"
                      type="checkbox"
                      render={({ input: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox checked={value} onChange={onChange} />
                            }
                            label="Выдали сертификат"
                          />
                        )
                      }}
                    />

                    <Field
                      name="needToRedirect"
                      type="checkbox"
                      render={({ input: { onChange, value } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox checked={value} onChange={onChange} />
                            }
                            label="Переадресация"
                          />
                        )
                      }}
                    />

                    {needToRedirect && (
                      <Field
                        name="redirectDeliveryAddress"
                        render={({ input: { onChange, value } }) => {
                          return (
                            <FormControl fullWidth>
                              <TextField
                                variant="outlined"
                                label="Адрес переадресации"
                                sx={{ mt: 2 }}
                                onChange={onChange}
                                value={value}
                              />
                            </FormControl>
                          )
                        }}
                      />
                    )}
                  </>
                )}
                {submitError && (
                  <Typography color="error">{submitError}</Typography>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  autoFocus
                  onClick={() => {
                    handleClose()
                    reset(DEFAULT_FORM_VALUES)
                  }}
                >
                  Отмена
                </Button>
                <Button type="submit">Ок</Button>
              </DialogActions>
            </form>
          )
        }}
      />
    </Dialog>
  )
}
