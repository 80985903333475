import { useEffect, useCallback, useState } from 'react'

export const tupelizePromise = (promise) => {
  return new Promise((resolve) =>
    promise.then(
      (data) => {
        return resolve([null, data])
      },
      (err) => {
        resolve([err, null])
      }
    )
  )
}

export const useFetch = (
  promiseFunction,
  fetchParams = { fetchOnMount: true, mountParams: [] }
) => {
  const { fetchOnMount, mountParams } = fetchParams

  const [data, setData] = useState(null)
  const [res, setRes] = useState(null)
  const [loading, setLoading] = useState(null)

  const [errorState, setErrorState] = useState(null)

  const fetchCallback = useCallback(
    async (...params) => {
      setLoading(true)

      const [error, response] = await tupelizePromise(
        promiseFunction(...params)
      )

      if (error && !response) {
        setErrorState(error)
        setLoading(false)
        return [error, null]
      }

      let respData = null
      respData = response.data

      setErrorState(null)
      setRes(response)
      setData(respData)
      setLoading(false)
      return [null, respData]
    },
    [setLoading, promiseFunction]
  )

  useEffect(() => {
    if (fetchOnMount) {
      fetchCallback(...mountParams)
    }
  }, [fetchCallback])

  return [
    {
      data,
      loading,
      error: errorState,
      response: res,
    },
    fetchCallback,
    setData,
  ]
}
