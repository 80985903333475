import React from 'react'
import { useHistory } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import icon from '../../assets/images/logoIcon.png'
import { loginUser } from '../../api'
import { useFetch } from '../../hooks'
import { saveTokenToLocalStorage } from '../../utils'
import { routes } from '../../routes'

import 'react-phone-input-2/lib/material.css'

export const Login = () => {
  const [, loginCallback] = useFetch(loginUser, { fetchOnMount: false })

  const history = useHistory()

  const formOnSubmit = async ({ phone, posPass }) => {
    const [error, data] = await loginCallback({ phone: `+${phone}`, posPass })

    if (!error) {
      const { token } = data
      saveTokenToLocalStorage(token)
      history.replace(routes.basePath)
      return
    }

    if (error) {
      return { [FORM_ERROR]: error.message }
    }
  }

  return (
    <Container
      sx={{
        bgcolor: 'orange',
      }}
    >
      <Box
        display="flex"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <Form
          onSubmit={formOnSubmit}
          render={({ handleSubmit, submitError }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Paper elevation={3}>
                  <Box display="flex" flexDirection="column" sx={{ p: 2 }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ mb: 2 }}
                    >
                      <img
                        src={icon}
                        alt="Mokopizza logo"
                        style={{ width: 42, height: 42 }}
                      />
                    </Box>
                    <Field
                      name="phone"
                      validate={(value) => {
                        if (value && value.length < 12) {
                          return 'Укажите правильно телефон'
                        }
                        return ''
                      }}
                      render={({
                        input: { onChange, value },
                        meta: { error },
                      }) => {
                        return (
                          <PhoneInput
                            country="ua"
                            countryCodeEditable={false}
                            inputProps={{
                              autoFocus: true,
                              required: true,
                            }}
                            specialLabel="Телефон"
                            placeholder="+380981111111"
                            disableDropdown
                            value={value}
                            onChange={onChange}
                            defaultErrorMessage={error}
                            isValid={() => {
                              if (error) {
                                return false
                              }
                              return true
                            }}
                          />
                        )
                      }}
                    />
                    <Field
                      name="posPass"
                      validate={(value) => {
                        if (
                          !value ||
                          (value && (value.length < 4 || value.length > 4))
                        ) {
                          return 'PIN код должен состоять из 4 символов'
                        }
                        return ''
                      }}
                      render={({
                        input: { onChange, value },
                        meta: { error, dirty },
                      }) => {
                        return (
                          <TextField
                            error={dirty && Boolean(error)}
                            variant="outlined"
                            label="Пароль"
                            sx={{ mt: 2 }}
                            onChange={onChange}
                            value={value}
                            type="password"
                            helperText={dirty && error}
                            required
                          />
                        )
                      }}
                    />

                    {submitError && (
                      <Typography
                        sx={{ color: 'error.main', mt: 2, maxWidth: 300 }}
                      >
                        {submitError}
                      </Typography>
                    )}

                    <Button variant="contained" sx={{ mt: 2 }} type="submit">
                      Войти
                    </Button>
                  </Box>
                </Paper>
              </form>
            )
          }}
        />
      </Box>
    </Container>
  )
}
