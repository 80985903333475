export const CARD_STATUSES = {
  Delivered: 'Доставлен',
  FailedDelivery: 'Неуспешная доставка',
  RepeatDelivery: 'Довоз/повторная доставка',
  CourierChange: 'Смена курьера',
  CancellationOfOrder: 'Отказ от заказа',
}

export const ALL_STATUSES = {
  InDelivery: 'Доставляется',
  ...CARD_STATUSES,
  CanceledByManager: 'Отменён менеджером.',
}
