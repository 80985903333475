export const routes = {
  basePath: '/',
  login: '/login',
  admin: '/admin',
  orders: '/orders',
  orderByPlace: '/orders_by_place',
}

export const routesWithNames = {
  [routes.basePath]: 'Активные заказы',
  [routes.orders]: 'Заказы за день',
  [routes.orderByPlace]: 'Заказы в заведении',
}
