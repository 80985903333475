import React from 'react'

import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  required,
  SelectInput,
  NumberInput,
  FormDataConsumer,
} from 'react-admin'

const DistrictEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <ReferenceInput source="place" reference="places" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="deliveryCost" validate={required()} />
      <SelectInput
        source="deliveryType"
        choices={[
          { id: 'District', name: 'Район доставки' },
          { id: 'Staff', name: 'Хоз.поручения' },
        ]}
        validate={required()}
      />
      <FormDataConsumer>
        {({ formData, ...otherProps }) => {
          const { deliveryType } = formData

          return (
            <>
              {deliveryType === 'District' && (
                <>
                  <TextInput
                    source="names"
                    validate={required()}
                    helperText="Названия районов доставки на других языках - разделять запятой!"
                    {...otherProps}
                  />
                </>
              )}
            </>
          )
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)

export default DistrictEdit
