import { xhr } from '../baseConfig'

const BASE_PATH = '/api/v1/courier'

export const activeOrdersList = (params) =>
  xhr.get(`${BASE_PATH}/orders/active`, params)

export const ordersListByDay = (params) =>
  xhr.get(`${BASE_PATH}/orders/by_day`, params)

export const updateOrderById = (id, params) =>
  xhr.put(`${BASE_PATH}/orders/${id}`, params)

export const visicomOrderAddressById = (id) =>
  xhr.get(`${BASE_PATH}/orders/${id}/visicom_address`)

export const ordersListByPlace = (params) =>
  xhr.get(`${BASE_PATH}/orders/by_place`, params)
